import React, {useState, useEffect} from 'react';
import {useParams, useNavigate, useOutletContext} from 'react-router-dom';
import {useMutation, useQuery} from 'react-query';
import {ArrowBack} from '@mui/icons-material';
import {Box, Button, Card, FormControl, FormControlLabel, Grid, Paper, Radio, RadioGroup, Typography, styled, Skeleton, Alert} from '@mui/material';

import LoadingIcon from 'src/components/Loading/loadingIcon';
import {getTransferMatchFile, getMatchJobStatus, transferMatchFile} from 'src/api';
import CancelButton from './cancelButton';
import FileStatusChip from 'src/components/FileStatusChip';
import TextWithSkeleton from 'src/components/TextWithSkeleton';


const PageWrapper = styled(Box)(
  ({theme}) => `
  .info-box {
    background-color: ${theme.palette.action.focus};
    border: 1px solid ${theme.palette.text.disabled};
  }
  `
);

function Loading() {
  return (
    <LoadingIcon contained={true} height="337px" marginTop="200px" />
  );
}

function DeliverFile() {
  const navigate = useNavigate();
  const {carrierId, matchJobId} = useParams();
  const [whenToDeliver, setWhenToDeliver] = useState('scheduled');
  const {carrier, matchJob} = useOutletContext();

  const {dataLoadId} = matchJob;
  const {isVerified} = carrier;

  const {
    data: matchJobStatusData
  } = useQuery(['getMatchJobStatus', carrierId, matchJobId, isVerified], () => getMatchJobStatus(carrierId, matchJobId), {enabled: isVerified});

  const {status, accepted: matchRecordsCount, rejected: noMatchRecordsCount} = matchJobStatusData || {};

  const {
    data: transferMatchFileData
  } = useQuery(['getSendReport', carrierId, dataLoadId], () => getTransferMatchFile(carrierId, dataLoadId), {enabled: !!dataLoadId});

  const {fileName, scheduledDeliveryDate, fileStatus: _fileStatus} = transferMatchFileData || {};

  const submitTransferMatchFile = useMutation({
    mutationFn: (payload) => transferMatchFile(carrierId, dataLoadId, payload),
    onSuccess: () => navigate(`/${carrierId}/all-match-results/${matchJobId}/match-results-file-details?fileDeliveryQueued=success`)
  });

  const sendFileToQueue = function() {
    submitTransferMatchFile.mutate({'transfer': whenToDeliver});
  };

  const handleRadioChange = (event) => {
    setWhenToDeliver(event.target.value);
  };

  const fileStatus = status || _fileStatus;
  const date = new Date().toLocaleDateString('en', {month: '2-digit', day: '2-digit', year: 'numeric'});

  useEffect(() => {
    if (!scheduledDeliveryDate || status === 'queuedForDelivery') {
      setWhenToDeliver('now');
    }
  }, [scheduledDeliveryDate, status]);

  return (
    <PageWrapper>
      <Grid container sx={{mt: 3, mb: 3}}>
        <Grid item xs={6}><Typography variant="h5">Deliver File</Typography></Grid>
        <Grid item xs={6} sx={{textAlign: 'right'}}><CancelButton variant="outlined" color="primary" size="small" startIcon={<ArrowBack />} >Back to File Details</CancelButton></Grid>
      </Grid>
      <Paper>
        <Card>
          {submitTransferMatchFile.isLoading ? <Loading/> :
            <>
              <Box sx={{p: 2, pb: 1}}>
                <Typography variant="h6" sx={{fontWeight: 700}}>Match File Overview</Typography>
              </Box>
              <hr />
              <Box sx={{p: 2, pb: 1}}>
                {submitTransferMatchFile.isError && <Alert severity="error" sx={{mb: 2}}>We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>}
                <TextWithSkeleton label='File Name' value={fileName} width='250px'/>
                <TextWithSkeleton label='Dataload ID' value={dataLoadId} includeCopyButton/>
                <Typography variant="subtitle1" sx={{mb: '7px'}} display="flex">File Status: <Typography ml={0.5} variant="body1" display="inline-block"><FileStatusChip fileStatus={fileStatus} isDataLoading={!fileStatus} size="small"/></Typography></Typography>
                <TextWithSkeleton label='Matched Records' value={matchRecordsCount} width='50px'/>
                <Typography variant="body2" sx={{mb: 1}}>Total includes ‘Net New’, ‘Updated (New)‘ and ‘Updated (Update)’ customer records that were a ‘Match’. If relevant, also included are ‘Deleted’ customer records.</Typography>
                <TextWithSkeleton label='No Match Records' value={noMatchRecordsCount} width='50px'/>
                <Typography variant="body2">Total includes ‘Net New’, ‘Updated (New)’ and ‘Updated (Update)‘ customer records that were a ‘No Match’. These records will not be included when this file is delivered.</Typography>
                <Typography sx={{mt: 2}} variant="subtitle1">Choose when to deliver this match file to the customer:</Typography>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={whenToDeliver}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel disabled={!scheduledDeliveryDate || fileStatus === 'queuedForDelivery'} value="scheduled" control={<Radio />} label="Send File on Transfer Schedule" />
                    <FormControlLabel value="now" control={<Radio />} label="Send File Now" />
                  </RadioGroup>
                </FormControl>
                <Box sx={{p: 3, pb: 4, mt: 2, mb: 2}} className="info-box">
                  <Typography variant="overline" color="text.primary">{whenToDeliver === 'scheduled' ? 'send file on transfer schedule' : 'send file today.'}</Typography>
                  <Typography variant="body1">
                    {whenToDeliver === 'scheduled' ?
                      <><Typography display="inline-block">Match file will be delivered to the customer on their configured transfer frequency</Typography> {scheduledDeliveryDate || <Skeleton variant='rectangular' width={225} height={12} sx={{display: 'inline-block', mt: '5px'}} />}.</> : `Match file will be delivered to the customer today ${date}. It could take a couple minutes to several hours before the file sends, so will move to Queued for Delivery.`}
                  </Typography>
                </Box>
              </Box>
              <hr />
              <Box sx={{p: 2, pb: 3}}>
                <Grid container>
                  <Grid item xs={6}>
                    <CancelButton variant="outlined" color="primary">Cancel</CancelButton>
                  </Grid>
                  <Grid item xs={6} sx={{textAlign: 'right'}}>
                    <Button onClick={sendFileToQueue} variant="contained" color="primary" disabled={!scheduledDeliveryDate && whenToDeliver === 'scheduled'}>Queue File for Delivery</Button>
                  </Grid>
                </Grid>
              </Box>
            </>
          }
        </Card>
      </Paper>
    </PageWrapper>
  );
}

export default DeliverFile;
