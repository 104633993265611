import {useEffect, useState} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import {Paper, Typography} from '@mui/material';
import {useQuery} from 'react-query';
import PropTypes from 'prop-types';

import {getMatches} from 'src/api';
import TableWithPagination from 'src/components/TableWithPagination';
import {SkeletonRow} from './allMatchResultsSkeleton';
import matchesColumns from './matchesColumns';


const mapColumnVisibility = (showMatchDecisionView) => {
  return {
    new: {
      carrierId: true,
      updateStatus: false,
      sourcesMatched: true,
      categories: true,
      matchDecision: showMatchDecisionView,
      reviewDate: showMatchDecisionView,
      reviewers: showMatchDecisionView,
      firstName: !showMatchDecisionView,
      lastName: !showMatchDecisionView,
      city: !showMatchDecisionView,
      state: !showMatchDecisionView,
      dob: !showMatchDecisionView,
      emId: false,
      source: false
    },
    updated: {
      carrierId: true,
      updateStatus: true,
      sourcesMatched: true,
      categories: true,
      matchDecision: showMatchDecisionView,
      reviewDate: showMatchDecisionView,
      reviewers: showMatchDecisionView,
      firstName: !showMatchDecisionView,
      lastName: !showMatchDecisionView,
      city: !showMatchDecisionView,
      state: !showMatchDecisionView,
      dob: !showMatchDecisionView,
      emId: false,
      source: false
    },
    deleted: {
      carrierId: false,
      updateStatus: false,
      sources: false,
      categories: false,
      matchDecision: false,
      reviewDate: false,
      reviewers: false,
      firstName: true,
      lastName: true,
      city: true,
      state: true,
      dob: true,
      matchId: true,
      source: true
    }
  };
};

const defaultPaginationParams = {
  limit: 10,
  next: '',
  previous: ''
};

function MatchResultsFileDetailsTable({isVerified, isCarrierError, paginationCache, setPaginationCache}) {
  const {carrierId, matchJobId} = useParams();
  const [searchParams] = useSearchParams();
  const showMatchDecisionView = searchParams.get('showMatchDecisionView') === 'true';
  const selectedSection = searchParams.get('section') || 'new';
  const selectedMatchDecision = searchParams.get('matchDecision');
  const selectedMatchType = searchParams.get('matchType');
  const selectedSources = searchParams.getAll('sources') || [];
  const selectedCategories = searchParams.getAll('categories') || [];
  const paginationParams = paginationCache[selectedSection] || defaultPaginationParams;

  const [rowCountCache, setRowCountCache] = useState({});
  const defaultRowCount = {
    rowStart: 1,
    rowEnd: paginationParams.limit
  };
  const currentRowCount = rowCountCache?.[selectedSection] || defaultRowCount;

  const matchResultsDetailsData = useQuery(['getMatches', paginationParams, matchJobId, selectedMatchDecision, selectedMatchType, selectedSources, selectedCategories, selectedSection], () => getMatches(carrierId, matchJobId, selectedSection, paginationParams.limit, paginationParams.previous, paginationParams.next, selectedMatchDecision, selectedMatchType, selectedSources, selectedCategories), {retry: false});

  useEffect(() => {
    if (!matchResultsDetailsData?.isLoading) {
      const rowStart = paginationParams.next === '' && paginationParams.previous === '' ? 1 : currentRowCount.rowStart;
      setRowCountCache((_cache) => {
        const cache = {..._cache};
        cache[selectedSection] = {
          rowStart,
          rowEnd: rowStart + matchResultsDetailsData?.data?.results?.length - 1
        };
        return cache;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchResultsDetailsData?.data, matchResultsDetailsData?.isLoading, paginationParams]);

  const nextPage = function() {
    setPaginationCache((_params) => {
      const params = {..._params};
      params[selectedSection] = {
        limit: paginationParams.limit,
        next: matchResultsDetailsData?.data?.pagination?.next,
        previous: ''
      };
      return params;
    });

    setRowCountCache((_rowCount) => {
      const rowCount = {..._rowCount};
      rowCount[selectedSection] = {
        rowStart: currentRowCount.rowStart + paginationParams.limit
      };
      return rowCount;
    });
  };

  const previousPage = function() {
    setPaginationCache((_params) => {
      const params = {..._params};
      params[selectedSection] = {
        limit: paginationParams.limit,
        next: '',
        previous: matchResultsDetailsData?.data?.pagination?.previous
      };
      return params;
    });

    setRowCountCache((_rowCount) => {
      const rowCount = {..._rowCount};
      rowCount[selectedSection] = {
        rowStart: currentRowCount.rowStart - paginationParams.limit
      };
    });
  };

  const setPageSize = (pageSize) => {
    setPaginationCache((_params) => {
      const params = {..._params};
      params[selectedSection] = {
        limit: pageSize,
        next: '',
        previous: ''
      };
      return params;
    });

    setRowCountCache({
      rowStart: 1,
      rowEnd: pageSize
    });
  };

  const emptyMessage = function() {
    if (selectedMatchDecision !== '' || selectedMatchType !== '' || selectedCategories?.length > 0 || selectedSources?.length > 0) {
      return 'No customer records found for the selected criteria.';
    }
    return 'No customer records to display.';
  }();

  return (
    <>
      <Paper sx={{mb: 2, mt: 1}}>
        <TableWithPagination
          columnVisibilityModel={mapColumnVisibility(showMatchDecisionView)[selectedSection]}
          rows={matchResultsDetailsData?.data?.results}
          columns={matchesColumns(selectedSection, searchParams)}
          isDataTableLoading={matchResultsDetailsData?.isLoading}
          rowHeight='auto'
          next={matchResultsDetailsData?.data?.pagination?.next}
          prev={matchResultsDetailsData?.data?.pagination?.previous}
          isApiError={isCarrierError || matchResultsDetailsData?.isError}
          SkeletonRow={<SkeletonRow selectedSection={selectedSection} isVerified={isVerified}/>}
          emptyMessage={emptyMessage}
          setPageSize={setPageSize}
          getNextPage={nextPage}
          getPreviousPage={previousPage}
          pageSize={paginationParams?.limit}
          rowStart={currentRowCount?.rowStart}
          rowEnd={currentRowCount?.rowEnd}
        />
      </Paper>
      {selectedSection === 'updated' && <Typography variant="body2">NOTE: Update match types have no match status shown because the customer ID had previous death record matches where that match decision was already made.</Typography>}
    </>
  );
}

MatchResultsFileDetailsTable.propTypes = {
  isVerified: PropTypes.bool.isRequired,
  isCarrierError: PropTypes.bool,
  paginationCache: PropTypes.object,
  setPaginationCache: PropTypes.func
};

export default MatchResultsFileDetailsTable;
