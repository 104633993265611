import {useState} from 'react';
import {Grid} from '@mui/material';
import PropTypes from 'prop-types';

import ActionBar from './ActionBar';
import MatchResultsFileDetailsTable from './MatchResultsFileDetailsTable';


function TableWithFilter({isVerified, netNewRecordsCount, updatedRecordsCount, deletedRecordsCount, categories, isCarrierError}) {
  const [paginationCache, setPaginationCache] = useState({});

  return (
    <>
      <ActionBar
        verified={isVerified}
        netNewRecordsCount={netNewRecordsCount}
        updatedRecordsCount={updatedRecordsCount}
        deletedRecordsCount={deletedRecordsCount}
        categories={categories}
        setPaginationCache={setPaginationCache}
      />
      <Grid container spacing={3} >
        <Grid item xs={12}>
          <MatchResultsFileDetailsTable
            isVerified={isVerified}
            isCarrierError={isCarrierError}
            paginationCache={paginationCache}
            setPaginationCache={setPaginationCache}
          />
        </Grid>
      </Grid>
    </>
  );
}

TableWithFilter.propTypes = {
  isVerified: PropTypes.bool.isRequired,
  netNewRecordsCount: PropTypes.number,
  updatedRecordsCount: PropTypes.number,
  deletedRecordsCount: PropTypes.bool,
  categories: PropTypes.array,
  isCarrierError: PropTypes.bool
};

export default TableWithFilter;
