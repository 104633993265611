import {BusinessCenterRounded, CancelScheduleSendRounded} from '@mui/icons-material';

import MenuSection from '../MenuSection';


function PrivateMatchesSidebarItems() {
  const displayItems = [
    {
      heading: 'Matches Private To Evadata',
      items: [
        {
          name: 'Canceled Match Jobs',
          icon: CancelScheduleSendRounded,
          link: 'all-match-results?tab=canceled',
          tiers: ['standard', 'lens-plus', 'lens-verified']
        },
        {
          name: 'Estimated Claims Match Jobs',
          icon: BusinessCenterRounded,
          link: 'all-match-results?tab=prescan',
          tiers: ['standard', 'lens-plus', 'lens-verified']
        }
      ]
    }
  ];

  return (
    <>
      {displayItems.map((section) => (
        <MenuSection heading={section.heading} items={section.items} key={section.heading}></MenuSection>
      ))}
    </>
  );
}

export default PrivateMatchesSidebarItems;
