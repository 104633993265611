
import {useSearchParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Grid, Typography} from '@mui/material';

import Breadcrumb from 'src/components/Breadcrumb';
import {breadcrumbTitleMap} from 'src/utilities/breadcrumbTitleMap';


function PageHeading({carrierId, customerId, matchJobId, section, showLookupBreadcrumb}) {
  const [searchParams] = useSearchParams();

  const modifiedSearchParams = new URLSearchParams(searchParams.toString());
  modifiedSearchParams.delete('updateType');

  const allMatchResultsTab = searchParams.get('allMatchResultsTab');

  const breadcrumbLinks = [
    {
      'title': breadcrumbTitleMap[allMatchResultsTab] || 'All Match Results',
      'link': `/${carrierId}/all-match-results?tab=${allMatchResultsTab}&filter=${searchParams.get('allMatchResultsFilter')}`
    },
    {
      'title': 'Match Result File Details',
      'link': `/${carrierId}/all-match-results/${matchJobId}/match-results-file-details?${modifiedSearchParams.toString()}&openTab=${section}`
    }
  ];
  const breadcrumbLookup = [
    {
      'title': 'Look Up ID',
      'link': `/${carrierId}/look-up-id?customerId=${customerId}`
    }
  ];
  const matchTypeLabels = {
    'new': 'Net New Matches',
    'deleted': 'Deleted Matches'
  };

  return (
    <Grid container sx={{mb: 0}}>
      <Grid item xs={6} sx={{mt: 2, mb: 2}}>
        <Typography variant='h5'>Customer Record Details
        </Typography>
        <Breadcrumb breadcrumbLinks={showLookupBreadcrumb ? breadcrumbLookup : breadcrumbLinks} currentPage=" Customer Record Details" mb={0} />
      </Grid>
      {matchTypeLabels[section] &&
        <Grid item xs={12}>
          <Typography variant="h6" sx={{fontWeight: 'bold', mt: 0, mb: 3}}>{matchTypeLabels[section]}</Typography>
        </Grid>
      }
    </Grid>
  );
}

PageHeading.propTypes = {
  carrierId: PropTypes.string,
  customerId: PropTypes.string,
  matchJobId: PropTypes.string,
  section: PropTypes.string,
  showLookupBreadcrumb: PropTypes.bool
};

export default PageHeading;
