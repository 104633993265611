import {useSearchParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {FormikProvider, useFormik} from 'formik';
import {Button, Grid, TextField, Typography, Autocomplete, Select, MenuItem, InputLabel, FormControl} from '@mui/material';

import {sources, setFilterQueryParams, getFilterQueryParams, clearFilterQueryParams} from '../filterSearchParamsFuncs';


function Filters({currentSection, showMatchTypeFilter, isVerified, categories, resetPagination, setSectionFilters}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const showMatchDecisionView = searchParams.get('showMatchDecisionView') === 'true';

  const _currentSection = currentSection || 'new';

  const sortedCategories = categories.sort(function(a, b) {
    return parseFloat(a) - parseFloat(b);
  });

  const onClearFilter = () => {
    resetPagination();
    clearFilterQueryParams(searchParams, setSearchParams);
  };

  const formik = useFormik({
    initialValues: getFilterQueryParams(searchParams),
    enableReinitialize: true,
    onSubmit: (values) => {
      resetPagination();
      setFilterQueryParams(searchParams, setSearchParams, values);
      setSectionFilters((_sectionFilter) => {
        const sectionFilter = {..._sectionFilter};
        sectionFilter[_currentSection] = values;
        return sectionFilter;
      });
    }
  });

  return (
    <FormikProvider enableReinitialize={true} value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item xs={1} sx={{pt: 2}}>
            <Typography sx={{display: 'inline-block', mt: '12px', mr: 2}} variant="body1">Filter by:</Typography>
          </Grid>
          <Grid item xs={8} sx={{pt: 2}}>
            <Grid container>
              <Grid item xs={12} sx={{pt: 1, mb: 2}}>
                <Autocomplete
                  name="selectedSources"
                  size="small"
                  className='autocomplete-tags'
                  multiple
                  onChange={(_event, newValue) => formik.setFieldValue('selectedSources', newValue)}
                  value={formik.values.selectedSources}
                  id="selectedSources"
                  options={sources}
                  getOptionLabel={(option) => option.display}
                  isOptionEqualToValue={(opt, val) => opt.value === val.value}
                  renderInput={(params) => (
                    <TextField {...params} label="Sources" placeholder="Select" />
                  )}
                  sx={{mr: 2}}
                />
                <Autocomplete
                  name="selectedCategories"
                  size="small"
                  sx={{mr: 2}}
                  className='autocomplete-tags'
                  onChange={(_event, newValue) => formik.setFieldValue('selectedCategories', newValue)}
                  value={formik.values.selectedCategories}
                  multiple
                  id="selectedCategories"
                  options={sortedCategories}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField {...params} label="Categories" placeholder="Select" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                {isVerified && currentSection !== 'deleted' &&
                  <FormControl sx={{display: 'inline-block', mr: 2}}>
                    <InputLabel className={showMatchDecisionView ? '' : 'disabled'} id="decision-select-helper-label">Match Decision</InputLabel>
                    <Select
                      name='selectedMatchDecision'
                      size="small"
                      labelId="decision-select-helper-label"
                      id="decision-select-helper"
                      label="Match Decision"
                      sx={{minWidth: '230px', textAlign: 'left', borderRadius: '6px'}}
                      MenuProps={{disableScrollLock: true}}
                      value={formik.values.selectedMatchDecision}
                      onChange={formik.handleChange}
                      disabled={!showMatchDecisionView}
                    >
                      <MenuItem value="">None</MenuItem>
                      <MenuItem value="todo">To Do</MenuItem>
                      <MenuItem value="accepted">Match</MenuItem>
                      <MenuItem value="rejected">No Match</MenuItem>
                    </Select>
                  </FormControl>
                }
                {showMatchTypeFilter &&
                  <FormControl sx={{display: 'inline-block'}}>
                    <InputLabel id="match-select-helper-label">Match Type</InputLabel>
                    <Select
                      name='selectedMatchType'
                      labelId="match-select-helper-label"
                      id="match-select-helper"
                      label="Match Type"
                      sx={{minWidth: '230px', mr: 2, textAlign: 'left', height: '40px', borderRadius: '6px'}}
                      MenuProps={{disableScrollLock: true}}
                      value={formik.values.selectedMatchType}
                      onChange={formik.handleChange}
                    >
                      <MenuItem value="">None</MenuItem>
                      <MenuItem value="new">New</MenuItem>
                      <MenuItem value="update">Update</MenuItem>
                    </Select>
                  </FormControl>
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} sx={{pt: 2}}>
            <Button type="submit" sx={{float: 'right'}} size="medium" className="view-results-btn" variant="outlined">View Results</Button>
            <Button sx={{float: 'right'}} size="medium" onClick={onClearFilter} className="view-results-btn" variant="text">Clear Filter</Button>
          </Grid>
        </Grid>
      </form>
    </FormikProvider>
  );
}

Filters.propTypes = {
  currentSection: PropTypes.string.isRequired,
  isVerified: PropTypes.bool.isRequired,
  showMatchTypeFilter: PropTypes.bool.isRequired,
  categories: PropTypes.array.isRequired,
  resetPagination: PropTypes.func,
  setSectionFilters: PropTypes.func
};

export default Filters;
