import {Box, Button, Typography} from '@mui/material';
import React from 'react';
import {Link} from 'react-router-dom';

import ErrorFileDownload from './errorFileDownload';
import FileStatusChip from 'src/components/FileStatusChip';


function getAllMatchesColumns(setIsDownloadError, carrierId, verified, matchResultsGroup, statusFilter) {
  return [
    {field: 'fileName',
      headerName: 'File Name',
      sortable: false,
      flex: 1.5,
      renderCell: (params) => {
        return (
          <div className="wrap-cell flex">
            <Typography variant="body2" pb={params.row.status === 'skipped' ? 2 : 0}>{params.row.status === 'skipped' ? 'N/A' : params.value}</Typography>
            {params.row.status === 'skipped' && <Typography variant="caption" ml={-3} pb={3}>{'\n\n(File skipped because it fell on a holiday.)'}</Typography>}
          </div>
        );
      }
    },
    {
      field: 'id',
      headerName: 'Dataload ID',
      sortable: false,
      flex: 1.5,
      renderCell: (params) => {
        return <div className="wrap-cell flex" ><Typography variant="body2">{params.value}</Typography></div>;
      }
    },
    {
      field: 'totalMatches',
      sortable: false,
      renderHeader: () => {
        return (
          <Typography variant="subtitle1" sx={{fontSize: '15px'}}>{verified && (matchResultsGroup === 'queuedForDelivery' || matchResultsGroup === 'delivered') ? 'Matched Records*' : 'Total Records'}</Typography>
        );
      },
      flex: 0.9,
      renderCell: (params) => {
        return <div className="wrap-cell flex" ><Typography variant="body2">{params.value}</Typography></div>;
      }
    },
    {
      field: 'rejectedMatches',
      headerName: 'No Match Records**',
      sortable: false,
      flex: 1.4,
      renderCell: (params) => {
        return <div className="wrap-cell flex" ><Typography variant="body2">{params.value}</Typography></div>;
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      flex: 0.8,
      renderCell: (params) => {
        return <div className={params.value + ' wrap-cell'}><FileStatusChip fileStatus={params.value} sx={{textTransform: 'capitalize'}} size="small"/></div>;
      }
    },
    {
      field: 'reviewCompleted',
      headerName: 'Review Completed Date',
      sortable: false,
      flex: 1.2,
      renderCell: (params) => {
        return <div className="wrap-cell flex" ><Typography variant="body2">{params.row.status === 'pendingReview' || params.row.status === 'reviewInProgress' ? '-' : params.value}</Typography></div>;
      }
    },
    {
      field: 'completed',
      sortable: false,
      renderHeader: () => (
        <strong>
          <Typography variant="subtitle1" sx={{fontSize: '15px'}}>{matchResultsGroup === 'delivered' ? 'Date Delivered' : 'Date'}
          </Typography>
        </strong>
      ),
      flex: 1,
      renderCell: (params) => {
        return <Box className="wrap-cell flex"><Typography variant="body2">{params.row.status === 'processing' || params.row.status === 'skipped' ? '-' : params.value}</Typography></Box>;
      }
    },
    {
      field: 'scheduledDeliveryDate',
      headerName: 'Scheduled Delivery Date',
      sortable: false,
      flex: 1.2,
      renderCell: (params) => {
        return <div className="wrap-cell flex" ><Typography variant="body2">{params.value || 'Delivery paused by Evadata'}</Typography></div>;
      }
    },
    {
      field: 'updated',
      headerName: 'Date Canceled',
      sortable: false,
      flex: 1.2,
      renderCell: (params) => <div className="wrap-cell flex" ><Typography variant="body2">{params.value || '-'}</Typography></div>
    },
    {
      field: 'action',
      renderHeader: () => {
        return (
          <Typography variant="subtitle1" sx={{fontSize: '15px'}}>{(matchResultsGroup === 'processing' ) ? 'Download' : 'Action'}</Typography>
        );
      },
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      filterable: false,
      hideable: false,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (params.row.status === 'fileFailed') {
          return <ErrorFileDownload carrierId={carrierId} setIsDownloadError={setIsDownloadError} dataLoadId={params.row.id} />;
        } else if (params.row.status !== 'processing' && params.row.status !== 'skipped') {
          return <div className="wrap-cell" >
            <Button variant="outlined" size="small" LinkComponent={Link} to={`${params.row.matchJobId}/match-results-file-details?allMatchResultsTab=${matchResultsGroup}&allMatchResultsFilter=${statusFilter}&showMatchDecisionView=${verified}`} sx={{minWidth: '30px'}}>
              File Details
            </Button></div>;
        }
      }
    }
  ];
}

export default getAllMatchesColumns;
