import {useCallback} from 'react';
import {useParams, useSearchParams, useOutletContext} from 'react-router-dom';
import {useQuery} from 'react-query';
import {uniq, uniqWith, isEqual} from 'lodash';
import {Box} from '@mui/material';

import {getCustomerRecordDetails, getMatchJobDetails} from 'src/api';
import MetadataBar from 'src/pages/CustomerRecordDetails/MetadataBar';
import {updateStatusMap} from 'src/utilities/updateStatusMap';
import {PageWrapper} from './styles';
import PageHeading from './pageHeading';
import UpdateButtons from './updateButtons';
import StatusBar from './statusBar';
import TopCards from './TopCards';
import MatchAccordionSection from './MatchAccordionSection';


const filterMatchesByUpdateType = (matches, updateType) => {
  if (updateType) {
    return matches?.filter((match) => updateStatusMap[match.updateStatus] === updateType);
  }

  const updateNewMatches = matches?.filter((match) => updateStatusMap[match.updateStatus] === 'new');
  if (updateNewMatches?.length > 0) {
    return updateNewMatches;
  }

  const updateUpdateMatches = matches?.filter((match) => updateStatusMap[match.updateStatus] === 'update');
  if (updateUpdateMatches?.length > 0) {
    return updateUpdateMatches;
  }
};

function CustomerRecordDetails() {
  const {carrier} = useOutletContext();
  const isVerified = carrier.isVerified;

  const {carrierId, matchJobId, customerId, section} = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const matchId = searchParams.get('matchId');
  const updateType = searchParams.get('updateType');

  const customerRecordResponse = useQuery(['customerRecordDetails'], () => getCustomerRecordDetails(carrierId, matchJobId, section, customerId), {retry: false, refetchInterval: 30000, staleTime: 25000});
  const {data: customerRecordData, isError: isCustomerRecordQueryError, isLoading: isCustomerRecordLoading} = customerRecordResponse;

  const matchJobResponse = useQuery(['matchJobDetails'], () => getMatchJobDetails(carrierId, matchJobId), {retry: false});
  const {data: matchJobData, isError: isMatchJobQueryError} = matchJobResponse;

  const refetchData = useCallback(() => {
    customerRecordResponse.refetch();
    matchJobResponse.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeUpdateType = (event) => {
    const updateType = event.target.id;
    searchParams.set('updateType', updateType);
    setSearchParams(searchParams);
  };

  const customerRecord = customerRecordData?.customer;

  const {fileName, dataLoadId, categories, sources} = matchJobData || {};
  const fileStatus = matchJobData?.status || matchJobData?.fileStatus;
  const matchDecision = customerRecordData?.matchDecision;

  const isCustomerRecordError = customerRecordData?.error || isCustomerRecordQueryError;
  const isMatchJobError = matchJobData?.error || isMatchJobQueryError;

  const showSingleMatch = searchParams.has('matchId');

  const displayedMatches = function() {
    const matches = matchId ? customerRecordData?.matches?.filter((match) => matchId === match.emId) : customerRecordData?.matches;

    return section === 'updated' ? filterMatchesByUpdateType(matches, updateType) : matches;
  }();

  const updateTypes = showSingleMatch ? [displayedMatches?.[0]?.updateStatus] : uniq(customerRecordData?.matches?.map((match) => updateStatusMap[match?.updateStatus]));

  const _reviewers = new Set();

  displayedMatches?.forEach((item) => {
    if (item.reviewer) {
      _reviewers.add(item?.reviewer);
    }
  });

  const reviewers = uniqWith(Array.from(_reviewers), isEqual);

  return (
    <PageWrapper>
      <MetadataBar
        carrierId={carrierId}
        matchJobId={matchJobId}
        section={section}
        fileName={fileName}
        dataloadId={dataLoadId}
        fileStatus={fileStatus}
        showBackBar={!showSingleMatch}
        isApiError={isCustomerRecordError || carrier?.isError}
      />
      <Box component="main" sx={{flexGrow: 1, width: '100%', mt: showSingleMatch ? 2 : 7}}>
        <PageHeading carrierId={carrierId} customerId={customerRecord?.customerId} matchJobId={matchJobId} section={section} showLookupBreadcrumb={showSingleMatch} />
        {section === 'updated' &&
            <UpdateButtons updateType={updateType} updateTypes={updateTypes} handleChangeUpdateType={handleChangeUpdateType} />
        }
        {isVerified && ((section === 'new') || (section === 'updated')) && !isCustomerRecordError && !carrier?.isError &&
            <StatusBar matchId={matchId} matchDecision={matchDecision} reviewers={reviewers} />
        }
        <TopCards
          carrierId={carrierId}
          customerRecord={customerRecord}
          matches={displayedMatches}
          isCustomerRecordError={isCustomerRecordError}
          isMatchJobError={isMatchJobError}
          isCarrierError={carrier?.isError}
          configCategories={categories}
          configSources={sources}
        />
        <MatchAccordionSection
          matches={displayedMatches}
          customerRecord={customerRecord}
          refetchData={refetchData}
          isVerified={isVerified}
          fileStatus={fileStatus}
          isLoading={isCustomerRecordLoading}
          isError={isCustomerRecordError}
        />
      </Box>
    </PageWrapper>
  );
}

export default CustomerRecordDetails;
