import React, {useEffect, useRef, useState} from 'react';

import {useOktaAuth} from '@okta/okta-react';
import {toRelativeUrl} from '@okta/okta-auth-js';
import {Outlet} from 'react-router-dom';

import InitialLoader from 'src/components/InitialLoader';
import LoadError from 'src/components/LoadError';


export const RequiredAuth = () => {
  const {oktaAuth, authState} = useOktaAuth();
  const pendingLogin = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [handleLoginError, setHandleLoginError] = useState(null);

  useEffect(() => {
    const handleLogin = async function() {
      if (pendingLogin.current) {
        return;
      }

      pendingLogin.current = true;

      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);
      await oktaAuth.signInWithRedirect();
    };

    if (!authState) {
      return;
    }

    if (authState.isAuthenticated) {
      pendingLogin.current = false;
      setIsLoading(false);
      return;
    }

    // Start login if app has decided it is not logged in and there is no pending signin
    if (!authState.isAuthenticated) {
      setIsLoading(true);
      handleLogin().catch((err) => {
        setHandleLoginError(err);
      });
      setIsLoading(false);
    }
  }, [authState, oktaAuth, isLoading]);

  if (handleLoginError) {
    return <LoadError/>;
  }

  return (
    <>
      {isLoading ? <InitialLoader/> : <Outlet />}
    </>
  );
};
