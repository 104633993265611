import {useState} from 'react';
import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Typography, styled, Alert} from '@mui/material';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {GridExpandMoreIcon} from '@mui/x-data-grid';

import {postMatchDecision} from 'src/api';
import {sourceDisplayMap} from 'src/utilities/mapSourceDisplay';
import {getReviewerName} from 'src/utilities/getReviewerName';
import {formatSsn} from 'src/utilities/formatSsn';
import RecordEntrySecondary from './recordEntrySecondary';
import RecordEntry from './recordEntry';
import MatchDecisionButtons from './matchDecisionButtons';
import secondaryAttributesCol1 from './secondaryAttributesList';
import secondaryAttributesCol2 from './secondaryAttributesList2';
import secondaryAttributesCol3 from './secondaryAttributesList3';
import TextWithSkeleton from 'src/components/TextWithSkeleton';


const ColumnHeader = () => {
  return (
    <Box className="column-header" sx={{mt: 2}}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="overline" color="text.primary" sx={{pl: 2}}>Your Customer List</Typography>
        </Grid>
        <Grid item xs={6} sx={{borderLeft: '1px solid #ccc', pl: 2}}>
          <Typography variant="overline" color="text.primary">Death Record</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

function MatchAccordion({matchData, refetchData, isVerified, customerData, fileStatus}) {
  const {carrierId, matchJobId, section, customerId} = useParams();

  const matchDecision = matchData?.matchDecision || matchData?.historical?.matchDecision;

  const [apiError, setApiError] = useState(false);
  const [newMatchDecision, setNewMatchDecision] = useState();

  const LoadingButtonGrid = styled(Grid)(
    () => `
      button.MuiLoadingButton-loading.accept {
        border: 1px solid rgba(24, 124, 21, 0.5);
        color: #187C15;
        background-color: rgba(24, 124, 21, 0.08);
      }

      button.MuiLoadingButton-loading.reject {
        border: 1px solid rgba(217, 0, 11, 0.5);
        color: #D9000B;
        background-color: rgba(217, 0, 11, 0.08);
      }

      button.MuiLoadingButton-loading.undo {
        border: 1px solid rgba(0, 99, 158, 0.5);
        color: rgba(0, 99, 158, 1);
        background-color: rgba(0, 99, 158, 0.08);
        min-width: 100px;
        justify-content: flex-end;
      }
    `
  );

  const updateStatus = async (newStatus) => {
    setNewMatchDecision(newStatus);
    setApiError(false);
    const payload = {
      matchDecision: newStatus
    };

    try {
      const response = await postMatchDecision(carrierId, matchJobId, section, customerId, matchData?.emId, payload);
      const matchDecision = response?.matchDecision;
      if (matchDecision) {
        refetchData();
        if (matchDecision === 'accepted' || matchDecision === 'rejected') {
          setAccordionOpen(false);
        }
      } else {
        setApiError(true);
      }
    } catch (error) {
      setApiError(true);
    }
  };

  const [accordionOpen, setAccordionOpen] = useState(false);
  const [showAdditionalDetails, setShowAdditionalDetails] = useState(false);

  function toggleAdditionalDetails() {
    setShowAdditionalDetails(!showAdditionalDetails);
  }

  const toggleAccordion = () => {
    setAccordionOpen(!accordionOpen);
  };

  return (

    <Accordion className="accordion" expanded={accordionOpen}>
      <AccordionSummary sx={apiError ? {flexDirection: 'row-reverse', height: '100px !important', pt: '80px', mb: '30px'} : {flexDirection: 'row-reverse'}} className="accordion-summary" expandIcon={<GridExpandMoreIcon onClick={toggleAccordion} />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Alert className={apiError ? 'show' : 'hide'} severity="error" sx={{top: '0px', left: '0px', position: 'absolute', width: '100%'}}>We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>
        <Grid container sx={{alignItems: 'center'}}>
          <Grid item xs={1} onClick={toggleAccordion}>
            <Typography variant="subtitle1">{matchData?.category}</Typography>
          </Grid>
          <Grid item xs={5} onClick={toggleAccordion}>
            <Typography variant="body1">Source: <Typography variant="subtitle1" display="inline">{sourceDisplayMap[matchData?.source]}</Typography></Typography>
            <TextWithSkeleton label='Match ID' labelVariant='body1' value={matchData?.emId} includeCopyButton/>
          </Grid>
          <Grid item xs={3} minHeight="67px" display="flex" onClick={toggleAccordion}>
            <div style={{alignContent: 'center'}}>
              {matchDecision && matchDecision !== 'todo' &&
                <>
                  <Typography variant="body2" sx={{color: 'text.primary'}}>Reviewer: <b>{getReviewerName(matchData?.reviewer) || getReviewerName(matchData?.historical?.reviewer)}</b></Typography>
                  <Typography variant="body2" color='text.secondary'>Review Date: {matchData?.reviewDate || matchData?.historical?.reviewDate}</Typography>
                </>
              }
            </div>
          </Grid>
          {isVerified && section !== 'deleted' ?
            <LoadingButtonGrid item xs={3} sx={{textAlign: 'right'}}>
              <MatchDecisionButtons updateStatus={updateStatus} fileStatus={fileStatus} matchDecision={matchDecision} newMatchDecision={newMatchDecision} />
            </LoadingButtonGrid> :
            <Grid item xs={3} sx={{minHeight: '67px'}} onClick={toggleAccordion}></Grid>
          }
        </Grid></AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={6}><Typography variant="body2" >Death ID: <Typography variant="subtitle2" display="inline" sx={{color: 'text.primary'}}> {matchData?.deathId}</Typography></Typography></Grid>
          <Grid item xs={6} sx={{textAlign: 'right'}}><Typography variant="body2"> Match Date: <Typography variant="subtitle2" display="inline" sx={{color: 'text.primary'}}>{matchData?.matchDate}</Typography></Typography></Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <ColumnHeader />
            <RecordEntry title='SSN' fieldName="ssn" customerListData={formatSsn(customerData?.ssn)} deathRecordData={formatSsn(matchData?.deathRecord?.ssn)} exactMatch={matchData?.matchMetadata?.ssnExactMatch} fuzzyMatch={matchData?.matchMetadata?.ssnFuzzyMatch} valid={matchData.deathRecord.isSsnValid} matchData={matchData} historical={matchData?.historical} prevExactMatch={matchData?.historical?.matchMetadata.ssnExactMatch} prevFuzzyMatch={matchData?.historical?.matchMetadata?.ssnFuzzyMatch} />
            <RecordEntry title='DOB' fieldName="dob" customerListData={customerData?.dob} deathRecordData={matchData?.deathRecord?.dob} exactMatch={matchData?.matchMetadata?.dobExactMatch} fuzzyMatch={matchData?.matchMetadata?.dobFuzzyMatch} matchData={matchData} historical={matchData?.historical} prevExactMatch={matchData?.historical?.matchMetadata?.dobExactMatch} prevFuzzyMatch={matchData?.historical?.matchMetadata?.dobFuzzyMatch} />
            <RecordEntry title='City' fieldName="residenceCity" customerListData={customerData?.city} deathRecordData={matchData?.deathRecord?.residenceCity} exactMatch={!Object.values(matchData?.matchMetadata?.cityExactMatch).every((item) => item === false)} matchData={matchData} historical={matchData?.historical} prevExactMatch={matchData?.historical?.matchMetadata?.cityExactMatch?.residenceCity} />
            <RecordEntry title='State' fieldName="residenceState" customerListData={customerData?.state} deathRecordData={matchData?.deathRecord?.residenceState} exactMatch={!Object.values(matchData?.matchMetadata?.stateExactMatch).every((item) => item === false)} matchData={matchData} historical={matchData?.historical} prevExactMatch={matchData?.historical?.matchMetadata?.stateExactMMatch} />
          </Grid>
          <Grid item xs={6}>
            <ColumnHeader />
            <RecordEntry title='First Name' fieldName="firstName" customerListData={customerData?.firstName} deathRecordData={matchData?.deathRecord?.firstName} exactMatch={matchData?.matchMetadata?.firstNameExactMatch} fuzzyMatch={matchData?.matchMetadata?.firstNameFuzzyMatch} matchData={matchData} historical={matchData?.historical} prevExactMatch={matchData?.historical?.matchMetadata?.firstNameExactMatch} prevFuzzyMatch={matchData?.historical?.matchMetadata?.firstNameFuzzyMatch} />
            <RecordEntry title='Last Name' fieldName="lastName" customerListData={customerData?.lastName} deathRecordData={matchData?.deathRecord?.lastName} exactMatch={matchData?.matchMetadata?.lastNameExactMatch} fuzzyMatch={matchData?.matchMetadata?.lastNameFuzzyMatch} matchData={matchData} historical={matchData?.historical} prevExactMatch={matchData?.historical?.matchMetadata?.lastNameExactMatch} prevFuzzyMatch={matchData?.historical?.matchMetadata?.lastNameFuzzyMatch} />
            <RecordEntry title='Middle Name' fieldName="middleName" customerListData={customerData?.middleName} deathRecordData={matchData?.deathRecord?.middleName} exactMatch={matchData?.matchMetadata?.middleNameExactMatch} fuzzyMatch={matchData?.matchMetadata?.middleNameFuzzyMatch} matchData={matchData} historical={matchData?.historical} prevExactMatch={matchData?.historical?.matchMetadata?.middleNameExactMatch} prevFuzzyMatch={matchData?.historical?.matchMetadata?.middleNameFuzzyMatch} />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{mt: 0}}>
          <Grid item xs={12} sx={{textAlign: 'center'}}>
            <Button className="additional-death-record-button" variant="text" size="medium" sx={{mb: 2, mt: 2}} onClick={toggleAdditionalDetails}> {showAdditionalDetails ? 'Hide' : 'Show'} Additional Death Record Details {showAdditionalDetails ? <ExpandLess /> : <ExpandMore />}</Button>
          </Grid>

          {showAdditionalDetails &&
            <>
              <Grid item xs={4}>
                {secondaryAttributesCol1?.map((entry) => (
                  <RecordEntrySecondary key={entry.fieldName} title={entry?.title} fieldName={entry?.fieldName} drawer={entry?.drawer} link={entry?.link} linkText={entry?.linkText} deathRecordData={matchData?.deathRecord?.[entry?.fieldName]} historical={matchData?.historical} matchData={matchData} customerData={customerData} />
                ))}
              </Grid>
              <Grid item xs={4}>
                {secondaryAttributesCol2?.map((entry) => (
                  <RecordEntrySecondary key={entry.fieldName} title={entry?.title} fieldName={entry?.fieldName} drawer={entry?.drawer} link={entry?.link} linkText={entry?.linkText} deathRecordData={matchData?.deathRecord?.[entry?.fieldName]} historical={matchData?.historical} matchData={matchData} customerData={customerData} />
                ))}
              </Grid>
              <Grid item xs={4}>
                {secondaryAttributesCol3?.map((entry) => (
                  <RecordEntrySecondary key={entry.title} title={entry?.title} fieldName={entry?.fieldName} drawer={entry?.drawer} link={entry?.link} linkText={entry?.linkText} deathRecordData={matchData?.deathRecord?.[entry?.fieldName]} historical={matchData?.historical} updatedFields={matchData?.updatedFields} matchData={matchData} customerData={customerData} />
                ))}
              </Grid>
            </>
          }
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

MatchAccordion.propTypes = {
  matchData: PropTypes.object.isRequired,
  refetchData: PropTypes.func,
  isVerified: PropTypes.bool,
  customerData: PropTypes.object,
  fileStatus: PropTypes.string
};

export default MatchAccordion;
