import {useSearchParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Button, ButtonGroup, Skeleton} from '@mui/material';

import {setFilterQueryParams, clearFilterQueryParams} from '../filterSearchParamsFuncs';


const countSkeleton = <Skeleton variant='rectangular' width={25} height={22} sx={{mx: '4px'}} />;

function SectionButtonGroup({netNewRecordsCount, updatedRecordsCount, deletedRecordsCount, sectionFilters}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedSection = searchParams.get('section') || 'new';

  const handleChangeSection = function(section) {
    searchParams.set('section', section);

    if (sectionFilters[section]) {
      setFilterQueryParams(searchParams, setSearchParams, sectionFilters[section]);
    } else {
      clearFilterQueryParams(searchParams, setSearchParams);
    }
  };

  return (
    <ButtonGroup sx={{mb: 2}}>
      <Button sx={{textTransform: 'none', fontWeight: 400}} onClick={() => handleChangeSection('new')} variant={selectedSection === 'new' ? 'contained' : 'outlined'}><strong>NET NEW</strong>&nbsp;Matches ({netNewRecordsCount === null ? countSkeleton : netNewRecordsCount})</Button>
      <Button sx={{textTransform: 'none', fontWeight: 400}} onClick={() => handleChangeSection('updated')} variant={selectedSection === 'updated' ? 'contained' : 'outlined'}><strong>UPDATED</strong>&nbsp;Matches ({updatedRecordsCount === null ? countSkeleton : updatedRecordsCount})</Button>
      <Button sx={{textTransform: 'none', fontWeight: 400}} onClick={() => handleChangeSection('deleted')} variant={selectedSection === 'deleted' ? 'contained' : 'outlined'}><strong>DELETED</strong>&nbsp;Matches ({deletedRecordsCount === null ? countSkeleton : deletedRecordsCount})</Button>
    </ButtonGroup>
  );
}

SectionButtonGroup.propTypes = {
  netNewRecordsCount: PropTypes.number,
  updatedRecordsCount: PropTypes.number,
  deletedRecordsCount: PropTypes.number,
  sectionFilters: PropTypes.object
};

export default SectionButtonGroup;
