import React from 'react';
import PropTypes from 'prop-types';
import {Box, Card, CardContent, Typography} from '@mui/material';

import DisplayConfiguredSources from 'src/components/DisplayConfiguredSources';
import LoadError from 'src/components/LoadError';
import TextWithSkeleton from 'src/components/TextWithSkeleton';


function DisplayFileDetails({fileName, dataloadId, categories, sources, minHeight, apiError = false}) {
  return (
    <Card sx={{borderRadius: '7px', mb: 3, minHeight: minHeight}}>
      <CardContent>
        <Typography variant="overline" color="text.primary">
          File Details
        </Typography>
        {apiError ? <Box sx={{pt: 3}}><LoadError /></Box>:
          <>
            <TextWithSkeleton label='File Name' value={fileName} width='250px'/>
            <TextWithSkeleton label='Dataload ID' value={dataloadId} includeCopyButton/>
            <DisplayConfiguredSources categories={categories} sources={sources}/>
          </>
        }
      </CardContent>
    </Card>
  );
}

DisplayFileDetails.propTypes = {
  fileName: PropTypes.string,
  dataloadId: PropTypes.string,
  categories: PropTypes.array,
  sources: PropTypes.array,
  minHeight: PropTypes.string,
  apiError: PropTypes.bool
};

export default DisplayFileDetails;
