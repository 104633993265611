import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';

import {getAutomations} from 'src/api';

import ActionBar from './actionBar';
import EmptyAutomations from './emptyAutomations';
import {SkeletonRow} from './skeletonRow';
import automationColumns from './automationColumns';
import AutomationsTable from './automationsTable';


function DisplayAutomationsTab({setDisableHeaderButtons}) {
  const {carrierId} = useParams();

  const [params, setParams] = useState({
    limit: 10,
    next: '',
    previous: ''
  });

  const {data, isLoading, isError} = useQuery(['automations', params], () => getAutomations(carrierId, params.limit, params.previous, params.next));

  useEffect(() => {
    setDisableHeaderButtons(isError || isLoading);
  }, [isError, isLoading, setDisableHeaderButtons]);

  const columnVisibilityModel = {
    id: true,
    name: true,
    description: true,
    configuredFiles: true,
    updated: true
  };

  return (
    <>
      <ActionBar description={'List of all current automations set up for file configurations.'} />
      {!isLoading && data?.results?.length < 1 && !isError ?
        <EmptyAutomations
          title='Create & Execute Automations'
          subtitle='Quickly accept or reject death record matches within match files by adding automations.'
          icon={'all'}
        /> :
        <AutomationsTable
          rows={data?.results}
          columns={automationColumns()}
          columnVisibilityModel={columnVisibilityModel}
          isDataTableLoading={isLoading}
          rowHeight='auto'
          next={data?.pagination?.next}
          prev={data?.pagination?.previous}
          pageSize={params?.limit}
          isApiError={isError}
          SkeletonRow={<SkeletonRow/>}
          setParams={setParams}
          handleSort
          initialState={{
            sorting: {
              sortModel: [{field: 'updated', sort: 'desc'}]
            }
          }}
        />
      }
    </>
  );
}

DisplayAutomationsTab.propTypes = {
  setDisableHeaderButtons: PropTypes.func.isRequired
};

export default DisplayAutomationsTab;
