import React from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';
import {ListSubheader, Box, List, styled, Grid, Divider} from '@mui/material';

import SidebarMenuItem from './SidebarMenuItem';


const MenuWrapper = styled(Box)(
  ({theme}) => `
    .MuiList-root {
      margin-bottom: ${theme.spacing(1.5)};
      padding: 0;
      & > .MuiList-root {
        padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
      }
      &.guides-bottom {
        margin-top: -34px !important;
      }
    }
    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      color: ${theme.sidebar.menuItemIconColor};
      padding: ${theme.spacing(1, 3.5)};
      line-height: 1.4;
    }
    .MuiSvgIcon-root {
      font-size: 26px !important;
    }
    .MuiList-root .current.MuiListItem-root .MuiButton-root:before, .MuiList-root .MuiListItem-root .MuiListItem-root .MuiButton-root:hover:before {
      opacity: 0 !important;
    }
    .MuiList-root .MuiListItem-root .MuiButton-root:after {
      background-color: ${theme.palette.primary.main} !important;
    }
  `
);

const SubMenuWrapper = styled(Box)(
  ({theme}) => `

    .MuiList-root {
      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(5.5)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          font-size: 17px !important;
          color: ${theme.sidebar.menuItemColor};
          background-color: ${theme.sidebar.menuItemBg};
          width: 100%;
          border-radius: 0;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 4)};
          text-transform: capitalize;

          &:after {
            content: '';
            position: absolute;
            height: 100%;
            right: 0;
            top: 0;
            width: 0;
            opacity: 0;
            transition: ${theme.transitions.create(['opacity', 'width'])};
            background: ${theme.sidebar.menuItemColorActive};
            border-top-left-radius: ${theme.general.borderRadius};
            border-bottom-left-radius: ${theme.general.borderRadius};
          }
    
          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }
          .MuiButton-startIcon {
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
            color: ${theme.sidebar.menuItemIconColor};
          }
          .MuiButton-endIcon {
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }
          &:hover {
            background-color: ${theme.sidebar.menuItemBgActive};
          }
          // &.active {
          //   &:after {
          //     width: 5px;
          //     opacity: 1;
          //   }
          // }
        }
        &.Mui-children {
          flex-direction: column;
          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(8)};
          }
        }
        .MuiCollapse-root {
          width: 100%;
          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }
          .MuiListItem-root {
            padding: 0;
            .MuiButton-root {
              padding: ${theme.spacing(0.7, 4, 0.7, 4.25)};
              font-size: 15px !important;
              font-weight: 400;
              .MuiBadge-root {
                right: ${theme.spacing(5.5)};
              }
              &:before {
                content: ' ';
                background: ${theme.sidebar.menuItemIconColorActive};
                opacity: 0;
                transition: ${theme.transitions.create([
    'transform',
    'opacity'
  ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }
              &:hover {
                background-color: ${theme.sidebar.menuItemBgActive};
                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
                &:after {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
      .current {
        &.MuiListItem-root {
          .MuiButton-root {
            background-color: ${theme.sidebar.menuItemBgActive};
            color: ${theme.sidebar.menuItemColorActive};
            .MuiButton-startIcon,
            .MuiButton-endIcon {
                color: ${theme.sidebar.menuItemIconColorActive};
            }
            &:after {
              width: 5px;
              opacity: 1;
            }
          }
          .MuiSvgIcon-root {
            color: ${theme.palette.primary.main}
          }
        }
      }
    }

    
`
);

const renderSidebarMenuItems = ({items, path, search, customClass}) => (
  <SubMenuWrapper>
    <List component="div" className="this">
      {items.reduce((menuItems, item) => reduceChildRoutes({menuItems, item, path, search, customClass}), [])}
    </List>
  </SubMenuWrapper>
);

const reduceChildRoutes = ({menuItems, item, path, search}) => {
  const key = item.name;
  const [linkPath, linkSearchParams] = item.link.split('?');
  const linkSubstring = linkPath.substring(linkPath.lastIndexOf('/') + 1);

  const pathSubstring = path.substring(path.lastIndexOf('/') + 1);

  const searchParamsMatch = (!linkSearchParams && !search.includes('canceled') && !search.includes('prescan')) || search.includes(linkSearchParams);

  if (item.items) {
    menuItems.push(
      <SidebarMenuItem
        key={key}
        open={false}
        name={item.name}
        icon={item.icon}
        link={item.link}
      >
        {renderSidebarMenuItems({path, items: item.items})}
      </SidebarMenuItem>
    );
  } else {
    menuItems.push(
      <SidebarMenuItem
        key={key}
        name={item.name}
        link={item.link}
        icon={item.icon}
        className={linkSubstring === pathSubstring && searchParamsMatch ? 'current' : ''}
      />
    );
  }

  return menuItems;
};

function MenuSection({heading, items, customClass}) {
  const location = useLocation();

  return (
    <MenuWrapper>
      <List
        className={customClass + 'hello'}
        component="div"
        subheader={
          <ListSubheader component="div" disableSticky>
            {heading}
          </ListSubheader>
        }
      >
        {renderSidebarMenuItems({
          items,
          path: location.pathname,
          search: location.search,
          customClass
        })}
      </List>
      {heading !== 'Documentation' &&
        <Grid container>
          <Grid item xs={12} sx={{pl: '31px', pr: '20px'}}>
            <Divider sx={{mt: 1, mb: 2}}/>
          </Grid>
        </Grid>
      }
    </MenuWrapper>
  );
}

MenuSection.propTypes = {
  heading: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    link: PropTypes.string,
    icon: PropTypes.elementType
  })),
  customClass: PropTypes.string
};

export default MenuSection;
