import {useState} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Box, Button, Card, CardContent, Grid, Skeleton, Typography, styled} from '@mui/material';
import {Visibility, VisibilityOff, OpenInNew} from '@mui/icons-material';

import LoadError from 'src/components/LoadError';
import {formatSsn} from 'src/utilities/formatSsn';
import {formatDateString} from 'src/utilities/formatDateString';
import TextWithSkeleton from 'src/components/TextWithSkeleton';


const LinkWrapper = styled(Link)(
  () => `
      text-decoration: underline;
  `
);

function CustomerRecordCard({carrierId, customerRecord, isError}) {
  const [showSsn, setShowSsn] = useState(false);

  const {ssn, customerId, dob, city, state, firstName, lastName, middleName} = customerRecord || {};

  const toggleShowSsn = () => {
    setShowSsn(!showSsn);
    setTimeout(() => setShowSsn(false), 5000);
  };

  const displaySsn = () => {
    if (ssn) {
      return <Typography sx={{ml: 1}} variant="body1" display="inline" >{showSsn ? formatSsn(ssn) : '•••-••-••••'}</Typography>;
    } else {
      return <Skeleton sx={{display: 'inline-block', ml: 1}} width={180} height={20} />;
    }
  };

  const isShowField = (field) => {
    return !customerRecord || field;
  };

  return (
    <Card sx={{borderRadius: '7px', mb: 3, minHeight: '260px'}}>
      <CardContent>
        <Typography variant="overline" color="text.primary">
          Customer Record Details
        </Typography>
        {isError ? <Box sx={{p: 3}}><LoadError /></Box> :
          <>
            <TextWithSkeleton label='Customer ID' value={customerId} includeCopyButton/>
            <Typography sx={{mb: 1}} ><LinkWrapper to={`/${carrierId}/look-up-id?customerId=${customerId}`} className="underline" underline="always" target="_blank">Look up customer's match history <OpenInNew sx={{fontSize: '18px', mb: '-4px', ml: '4px'}} /></LinkWrapper></Typography>
            <Grid container>
              <Grid item xs={6}>
                {isShowField(ssn) && <Typography variant='subtitle1' className="top-card-flex">SSN: {displaySsn()}<Button className="ssn-btn" onClick={toggleShowSsn}>{(showSsn && ssn) ? <Visibility className="ssn-btn-icon" /> : <VisibilityOff className="ssn-btn-icon" />}</Button></Typography>}
                {isShowField(dob) && <TextWithSkeleton label='DOB' value={dob && formatDateString(dob)} width='190px'/>}
                {isShowField(city) && <Typography variant='subtitle1'>City: <Typography variant="body1" display="inline" sx={{textTransform: 'capitalize'}}>{city?.toLowerCase() || <Skeleton sx={{display: 'inline-block'}} width={190} height={20} />}</Typography></Typography>}
                {isShowField(state) && <Typography variant='subtitle1'>State: <Typography variant="body1" display="inline" sx={{textTransform: 'capitalize'}}>{state || <Skeleton sx={{display: 'inline-block'}} width={40} height={20} />}</Typography></Typography>}
              </Grid>
              <Grid item xs={6}>
                {isShowField(firstName) && <Typography variant='subtitle1'>First Name: <Typography variant="body1" display="inline" sx={{textTransform: 'capitalize'}}>{firstName?.toLowerCase() || <Skeleton sx={{display: 'inline-block'}} width={150} height={20} />}</Typography></Typography>}
                {isShowField(lastName) && <Typography variant='subtitle1'>Last Name: <Typography variant="body1" display="inline" sx={{textTransform: 'capitalize'}}>{lastName?.toLowerCase() || <Skeleton sx={{display: 'inline-block'}} width={155} height={20} />}</Typography></Typography>}
                {isShowField(middleName) && <Typography variant='subtitle1'>Middle Name: <Typography variant="body1" display="inline" sx={{textTransform: 'capitalize'}}>{middleName?.toLowerCase() || <Skeleton sx={{display: 'inline-block'}} width={135} height={20} />}</Typography></Typography>}
              </Grid>
            </Grid>
          </>
        }
      </CardContent>
    </Card>
  );
}

CustomerRecordCard.propTypes = {
  carrierId: PropTypes.string,
  customerRecord: PropTypes.object,
  isError: PropTypes.bool
};

export default CustomerRecordCard;
