import {Box, Button, Chip, Typography, Tooltip, IconButton, styled} from '@mui/material';
import {Link} from 'react-router-dom';
import {SmartToyRounded} from '@mui/icons-material';

import {sourceDisplayMap} from 'src/utilities/mapSourceDisplay';
import {getReviewerName} from 'src/utilities/getReviewerName';
import MatchDecisionChip from 'src/components/MatchDecisionChip';


const ReviewerWrapper = styled(Box)(
  ({theme}) => `
    .MuiIconButton-root:hover {
      background-color: ${theme.colors.primary.lighter};
    }
    svg.MuiSvgIcon-root:hover {
      color: ${theme.colors.primary.main};
    }
  `
);

const basicCellValue = (params) => <div className="wrap-cell top-align" ><Typography variant="body2">{params?.value}</Typography></div>;

function matchesColumns(section, searchParams) {
  const columns = [
    {
      field: 'customerId', headerName: 'Customer ID', sortingOrder: ['desc', 'asc'], flex: 1.4, renderCell: (params) => {
        return (
          <div className="wrap-cell top-align">
            <Typography variant="body2">{params.value}</Typography>
          </div>
        );
      }
    },
    {
      field: 'emId', headerName: 'Match ID', sortingOrder: ['desc', 'asc'], flex: 1.8, renderCell: (params) => { // for deleted matches only.
        return basicCellValue(params);
      }
    },
    {
      field: 'updateStatus', headerName: 'Match Type', sortingOrder: ['desc', 'asc'], flex: 1, renderCell: (params) => {
        const _types = params?.value;
        return (
          <Box className="wrap-cell top-align">
            {_types?.map((type, index) => (
              <Typography key={type + '-' + params?.row?.emId} variant="body2" sx={{textTransform: 'capitalize'}}>{type}{index !== (_types.length - 1) && ', '}</Typography>
            ))}
          </Box>
        );
      }
    },
    {
      field: 'sources', headerName: 'Sources Matched', sortingOrder: ['desc', 'asc'], flex: 1.3, renderCell: (params) => {
        const _sources = params?.value;
        return (
          <Box className="wrap-cell top-align">
            <Box>
              {_sources?.map((source, index) => (
                <Typography key={source} variant="body2">{sourceDisplayMap[source]}{index !== (_sources?.length - 1) && ','}</Typography>
              ))}
            </Box>
          </Box>
        );
      }
    },
    {
      field: 'source', headerName: 'Source', sortingOrder: ['desc', 'asc'], flex: 1.4, renderCell: (params) => {
        return (
          <Box className="wrap-cell customer-id top-align">
            <Box>
              <Typography variant="body2">{sourceDisplayMap[params?.value]}</Typography>
            </Box>
          </Box>
        );
      }
    },
    {
      field: 'categories', headerName: 'Matched Categories', sortingOrder: ['desc', 'asc'], flex: 1.3, renderCell: (params) => {
        const _categories = params?.value;
        return (
          <Box className="wrap-cell customer-id flex" sx={{maxHeight: '100px', mt: 1, flexWrap: 'wrap', height: 'auto !important', alignContent: 'flex-start'}}>
            {_categories?.map((cat) => (
              <Chip key={cat} sx={{mr: '3px', mb: '3px'}} label={cat} />
            ))}
          </Box>
        );
      }
    },
    {
      field: 'matchDecision', headerName: 'Match Decision', sortingOrder: ['desc', 'asc'], flex: 1.2, renderCell: (params) => {
        let matchDecision;
        if (!params?.value) {
          matchDecision = '-';
        } else {
          matchDecision = <MatchDecisionChip matchDecision={params?.value}/>;
        }

        return (
          <div className="wrap-cell top-align-chip"><Typography variant="body2">{matchDecision}</Typography></div>
        );
      }
    },
    {
      field: 'reviewDate', headerName: 'Decision Date', sortingOrder: ['desc', 'asc'], flex: 1.4,
      renderCell: (params) => {
        return <div className="wrap-cell top-align"><Typography variant="body2">{params.value || '-'}</Typography></div>;
      }
    },
    {
      field: 'reviewers', headerName: 'Reviewers', sortingOrder: ['desc', 'asc'], flex: 1.4,
      renderCell: (params) => {
        return (
          <div className="wrap-cell top-align">
            <ReviewerWrapper>
              {(params?.value?.length === 0 || !params.value) ? '-' : params?.value?.map((reviewer, index) => (
                <Typography key={getReviewerName(reviewer) + '-' + params.row.customerId} variant="body2">
                  {reviewer?.type === 'bot' &&
                  <Tooltip arrow title='Category match made by Periscope Automation'>
                    <IconButton size='small' sx={{mt: '-2px', mr: '2px'}}>
                      <SmartToyRounded color='disabled' sx={{fontSize: '20px'}} />
                    </IconButton>
                  </Tooltip>}
                  {getReviewerName(reviewer)}{index !== (params.value.length - 1) && ','}
                </Typography>
              ))}
            </ReviewerWrapper>
          </div>
        );
      }
    },
    {
      field: 'dob', headerName: 'DOB', sortingOrder: ['desc', 'asc'], flex: 0.8,
      renderCell: (params) => {
        return basicCellValue(params);
      }
    },
    {
      field: 'firstName', headerName: 'First Name', sortingOrder: ['desc', 'asc'], flex: 0.8,
      renderCell: (params) => {
        return basicCellValue(params);
      }
    },
    {
      field: 'lastName', headerName: 'Last Name', sortingOrder: ['desc', 'asc'], flex: 0.8,
      renderCell: (params) => {
        return basicCellValue(params);
      }
    },
    {
      field: 'city', headerName: 'City', sortingOrder: ['desc', 'asc'], flex: 1,
      renderCell: (params) => {
        return basicCellValue(params);
      }
    },
    {
      field: 'state', headerName: 'State', sortingOrder: ['desc', 'asc'], flex: 0.6,
      renderCell: (params) => {
        return basicCellValue(params);
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1.5,
      sortable: false,
      sortingOrder: ['desc', 'asc'],
      cellClassName: 'cat-level action-col',
      renderCell: (params) => {
        let _section = section;
        if (section === 'updated' && params?.row?.matchType?.length === 2) {
          _section = 'updated-both';
        }
        if (section === 'updated' && params?.row?.matchType?.length === 1) {
          _section = 'updated-' + params?.row?.matchType[0]?.toLowerCase();
        }
        return (
          <div className="wrap-cell top-align-chip">
            <Typography variant="body2">
              <Button variant="outlined" size="small" LinkComponent={Link} to={`${_section}/${params?.row?.customerId}/customer-record-details?${searchParams.toString()}`}>
                Record Details
              </Button>
            </Typography>
          </div>
        );
      }
    }
  ];

  return columns;
}

export default matchesColumns;
