
import React from 'react';
import PropTypes from 'prop-types';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import {Button, ButtonGroup, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Skeleton, Typography} from '@mui/material';

import {statusMap} from 'src/utilities/statusMap';
import {getMatchResultsMeta} from 'src/api';


function ActionBar({matchResultsGroup, selectMatchResultsGroup, statusFilter, handleFilterChange, verified, isCarrierError}) {
  const {carrierId} = useParams();
  const {isLoading, data} = useQuery(['getMatchResultsMeta', carrierId], () => getMatchResultsMeta(carrierId), {retry: false});

  const {processing=0, underReview=0, queuedForDelivery=0, delivered=0} = isCarrierError || !data ? {} : data;

  const countSkeleton = <Skeleton variant='rectangular' width={25} height={22} sx={{mx: '4px'}} />;

  const groupMap = [
    {
      group: 'processing',
      label: 'PROCESSING',
      count: processing,
      display: true
    },
    {
      group: 'underReview',
      label: 'UNDER REVIEW',
      count: underReview,
      display: verified
    },
    {
      group: 'queuedForDelivery',
      label: 'QUEUED FOR DELIVERY',
      count: queuedForDelivery,
      display: true
    },
    {
      group: 'delivered',
      label: 'DELIVERED',
      count: delivered,
      display: true
    }
  ];

  const filtersByMatchGroup = {
    processing: ['all', 'processing', 'skipped', 'fileFailed'],
    underReview: ['all', 'pendingReview', 'reviewInProgress', 'reviewCompleted'],
    delivered: ['all', 'fileDelivered', 'deliveryFailed']
  };

  const statusFilterMenuItems = filtersByMatchGroup[matchResultsGroup];

  const subtitleText = function(verified) {
    return {
      processing: verified ? 'Match files currently processing. When processing is finished, the files will advance to under review.' : 'Match files currently processing. When processing is finished, the files will advance to queued for delivery.',
      prescan: 'Viewing all match results files generated from Estimated Claims Report requests.',
      underReview: 'Manage death record matches for customer records in each match files shown.',
      queuedForDelivery: 'Match files queued for delivery to the customer\'s transfer location. Files will advance to delivered on the scheduled delivery date.',
      delivered: 'Match files delivered to the customer\'s transfer location.',
      canceled: 'Viewing all match results jobs canceled by internal Evadata team.'
    };
  };

  return (
    <Paper sx={{py: 3, px: 2, mb: 2}}>
      <Grid container>
        {matchResultsGroup !== 'canceled' && matchResultsGroup !== 'prescan' &&
          <>
            <Grid item xs={10}>
              <ButtonGroup sx={{mb: 4}}>
                {
                  groupMap.map((val) => {
                    return val.display &&
                      <Button
                        key={val.group}
                        sx={{textTransform: 'none', fontWeight: 400}}
                        onClick={() => selectMatchResultsGroup(val.group)}
                        variant={matchResultsGroup === val.group ? 'contained' : 'outlined'}
                      >
                        <strong>{val.label}</strong>&nbsp;({!isCarrierError && isLoading ? countSkeleton : val.count})
                      </Button>;
                  })
                }
              </ButtonGroup>
            </Grid>
            <Grid item xs={2} sx={{textAlign: 'right'}}>
              {matchResultsGroup !== 'queuedForDelivery' && matchResultsGroup !== 'prescan' &&
                <FormControl>
                  <InputLabel id="select-helper-label">Filter by Status</InputLabel>
                  <Select
                    labelId="select-helper-label"
                    id="select-helper"
                    label="Filter by Status"
                    sx={{minWidth: '160px', mr: 2, textAlign: 'left'}}
                    MenuProps={{disableScrollLock: true}}
                    value={statusFilter}
                    onChange={handleFilterChange}
                  >
                    {statusFilterMenuItems?.map((item) => (
                      <MenuItem key={item} value={item}>{statusMap[item]}</MenuItem>
                    ))
                    }
                  </Select>
                </FormControl>
              }
            </Grid>
          </>
        }
        <Grid item xs={12}>
          <Typography variant='subtitle1'>{subtitleText(verified)[matchResultsGroup]}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

ActionBar.propTypes = {
  matchResultsGroup: PropTypes.string.isRequired,
  selectMatchResultsGroup: PropTypes.func.isRequired,
  statusFilter: PropTypes.string.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  verified: PropTypes.bool.isRequired,
  isCarrierError: PropTypes.bool
};

export default ActionBar;
