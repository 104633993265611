import {useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Grid, Paper, styled} from '@mui/material';

import SectionButtonGroup from './SectionButtonGroup';
import Filters from './Filters';
import MatchDecisionToggle from './MatchDecisionToggle';
import SectionText from './SectionText';


const ActionWrapper = styled(Grid)(
  ({theme}) => `
    .autocomplete-tags {
      display: inline-block;
    }
    .autocomplete-tags .MuiInputBase-root {
      border-radius: 6px !important;
      width: 470px;
    }
    .autocomplete-tags .MuiAutocomplete-input {
      padding-top: 1px;
      padding-bottom: 3px;
    }
    .MuiInputLabel-root.MuiFormLabel-root{
      line-height: 0.8em !important;
      overflow: visible;
    }
    .autocomplete-tags .MuiInputLabel-root.MuiFormLabel-root {
      line-height: 1.4em !important;
    }
    .autocomplete-tags .MuiChip-deleteIcon {
      color: ${theme.palette.gray.medium};
    }
    .MuiAutocomplete-clearIndicator:hover, .MuiAutocomplete-clearIndicator {
      background: transparent;
      color: ${theme.palette.gray.dark};
    }
    .view-results-btn {
      color: ${theme.palette.primary.dark};
    }
    .Mui-disabled .MuiOutlinedInput-notchedOutline{
      border-color: ${theme.palette.gray.extraLight} !important;
    }
    .MuiSelect-icon.Mui-disabled {
      color: ${theme.palette.gray.light} 
    }
    .disabled {
      color: ${theme.palette.gray.light};
    }
  `
);

function ActionBar({verified, netNewRecordsCount, updatedRecordsCount, deletedRecordsCount, categories, setPaginationCache}) {
  const [searchParams] = useSearchParams();
  const selectedSection = searchParams.get('section') || 'new';
  const showMatchTypeFilter = selectedSection === 'updated';
  const [sectionFilters, setSectionFilters] = useState({});

  const resetPagination = () => {
    setPaginationCache((_params) => {
      const params = {..._params};
      params[selectedSection] = {
        limit: params?.[selectedSection]?.limit || 10,
        next: '',
        previous: ''
      };
      return params;
    });
  };

  return (
    <Paper sx={{py: 2, px: 2, mb: 2, minHeight: '160px'}}>
      <ActionWrapper container>
        <Grid item xs={8}>
          <SectionButtonGroup
            deletedRecordsCount={deletedRecordsCount}
            netNewRecordsCount={netNewRecordsCount}
            updatedRecordsCount={updatedRecordsCount}
            sectionFilters={sectionFilters}
          />
        </Grid>
        <Grid item xs={4} sx={{textAlign: 'right'}}>
          {verified && selectedSection !== 'deleted' && <MatchDecisionToggle isVerified={verified}/>}
        </Grid>
        <Grid item xs={12}>
          <SectionText section={selectedSection} />
        </Grid>

        {selectedSection !== 'deleted' &&
          <Grid item xs={12}>
            <Filters
              categories={categories}
              currentSection={selectedSection}
              showMatchTypeFilter={showMatchTypeFilter}
              isVerified={verified}
              resetPagination={resetPagination}
              setSectionFilters={setSectionFilters}
            />
          </Grid>
        }
      </ActionWrapper>
    </Paper>
  );
}

ActionBar.propTypes = {
  verified: PropTypes.bool.isRequired,
  netNewRecordsCount: PropTypes.number,
  updatedRecordsCount: PropTypes.number,
  deletedRecordsCount: PropTypes.number,
  categories: PropTypes.array,
  setPaginationCache: PropTypes.func
};

export default ActionBar;
