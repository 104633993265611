import {Typography, Skeleton} from '@mui/material';
import PropTypes from 'prop-types';
import {isNil} from 'lodash-es';

import CopyButton from './copyButton';


function TextWithSkeleton({label, labelVariant='subtitle1', value, includeCopyButton, width='350px', ...props}) {
  return (
    <Typography {...props} variant={labelVariant}>{label}:
      {isNil(value) ?
        <>
          {includeCopyButton && <CopyButton disabled/>}
          <Skeleton sx={{display: 'inline-block', ml: 0.5, verticalAlign: 'middle'}} width={width} height='20px'/>
        </> :
        <>
          {includeCopyButton && <CopyButton toolTipText={`Copy ${label}`} textToCopy={value}/>}
          <Typography display='inline' ml={0.5}>{value}</Typography>
        </>
      }
    </Typography>
  );
}

TextWithSkeleton.propTypes = {
  label: PropTypes.string,
  labelVariant: PropTypes.string,
  value: PropTypes.string,
  includeCopyButton: PropTypes.bool,
  width: PropTypes.string
};

export default TextWithSkeleton;
