import {useSearchParams} from 'react-router-dom';
import {Switch, Tooltip, Typography} from '@mui/material';


function MatchDecisionToggle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const isToggleChecked = searchParams.get('showMatchDecisionView') === 'true';

  const toggleMatchDecisionView = function() {
    searchParams.set('showMatchDecisionView', !isToggleChecked);
    setSearchParams(searchParams);
  };

  return (
    <>
      <Typography display="inline" variant="subtitle1">Match Decision View</Typography>
      <Tooltip arrow placement="left" title={isToggleChecked ? 'Turn OFF Match Decision View' : 'Turn ON Match Decision View'} >
        <Switch checked={isToggleChecked} onChange={toggleMatchDecisionView} />
      </Tooltip>
    </>
  );
}

export default MatchDecisionToggle;
