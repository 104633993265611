import {useEffect, useRef, useState} from 'react';
import {styled, Chip, Tooltip, Button, Box} from '@mui/material';
import {CheckCircleOutlineRounded, ContentCopyRounded} from '@mui/icons-material';
import PropTypes from 'prop-types';


const ButtonContainer = styled(Box)(
  ({theme}) => `
    .default-chip {
      background-color: ${theme.palette.success.main};
      color: ${theme.palette.common.white};
      font-weight: bold;
      position: absolute;
      margin-top: 0px;
      margin-left: -20px;
      z-index: 999999;
      .MuiChip-deleteIcon {
        color: ${theme.palette.common.white};
      }
    }
  `
);

function CopyButton(props) {
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setWasCopied(false);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const [wasCopied, setWasCopied] = useState(false);

  function copyText() {
    navigator.clipboard.writeText(props.textToCopy).catch(setWasCopied(false));
    setWasCopied(true);
  }

  const [buttonWidth, setButtonWidth] = useState('25px');

  useEffect(() => {
    if (props?.buttonText) {
      setButtonWidth('auto');
    }
  }, [setButtonWidth, props?.buttonText]);

  return (
    <ButtonContainer sx={{display: 'inline'}} ref={wrapperRef}>
      <Button disabled={props?.disabled} className="pgp-button" onClick={copyText} sx={{width: buttonWidth, minWidth: '20px', height: '35px', pl: props.paddingLeft}} >
        {props?.buttonText &&
          <Box sx={{mr: 1}}>
            {props?.buttonText}
          </Box>
        }
        <Tooltip arrow placement="right" title={props.toolTipText}><ContentCopyRounded sx={{ml: '0px'}}></ContentCopyRounded></Tooltip></Button>
      {wasCopied && <Chip onDelete className="default-chip" label="Copied to Clipboard" deleteIcon={<CheckCircleOutlineRounded className="check" />} />}
    </ButtonContainer>
  );
}

CopyButton.propTypes = {
  textToCopy: PropTypes.string,
  buttonText: PropTypes.string,
  paddingLeft: PropTypes.string,
  toolTipText: PropTypes.string,
  disabled: PropTypes.bool
};

export default CopyButton;
